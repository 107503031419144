/*
 *
 */
import * as React from 'react';
import styled, { keyframes } from 'styled-components';
import Button, { Button as BtnClass } from '../button';
import superagent from 'superagent';
import icon from './success.svg';

/*
 *
 */
const Main = styled.div`

`;

const Content = styled.div`
	margin:0 0 4rem;

	font-size:1.66rem;
	line-height:1.4em;
	letter-spacing:0.015em;

	& strong {
		font-weight:500;
	}

	& > :first-child {
		margin-top:0;
		margin-bottom:0;
	}

	@media (min-width:768px) {
		font-size:1.9rem;
	}

	@media (min-width:1024px) {
		font-size:2.1rem;
	}
`;

const Split = styled.div`
	margin:0 0 4rem;

	@media (min-width:768px) {
		display:flex;
		align-items:center;
		& > ${BtnClass} {
			flex:0 0 auto;
		}
	}
`;

const Note = styled.p`
	margin:2rem 0 0;

	opacity:.66;

	font-style:italic;
	font-size:1.575rem;

	@media (min-width:768px) {
		margin:0;
		padding-left:3rem;
	}
`;

const Form = styled.form`
	max-width:768px;
`;

const Row = styled.div`
	@media (min-width:768px) {
		margin:0 -1.5rem 2.5rem;
		display:flex;
	}
`;

const Field = styled.p`
	margin:0 0 2.5rem;

	@media (min-width:768px) {
		margin:0;
		width:50%;
		padding:0 1.5rem;
		box-sizing:border-box;

		&:first-child:last-child {
			width:100%;
		}
	}
`;

const Label = styled.label`
	margin:0 0 .66rem;
	display:table;

	font-size:1.6rem;
	font-weight:500;
	letter-spacing:0.01em;

	${Field}.has-error & {
		color:#a94442;
	}
`;

const Input = styled.input`
	width:100%;
	height:45px;
	padding:1em;
	border:1px solid #979797;
	box-sizing:border-box;
	appearance:none;
	border-radius:0;
	outline:0;
	background:#F6F6F5;

	&:focus {
		border-color:#000;
	}

	&:-webkit-autofill,
	&:-webkit-autofill:hover,
	&:-webkit-autofill:focus,
	&:-webkit-autofill:active
	{
		-webkit-box-shadow: 0 0 0 30px #F6F6F5 inset;
	}

	font-size:1.5rem;
	font-family:Helvetica, Arial, sans-serif;

	${Field}.has-error &,
	${Field}.has-error &:focus {
		color:#a94442;
		border-color:#a94442;
		background-color:#f2dede;
	}

	${Field}.has-error &:-webkit-autofill,
	${Field}.has-error &:-webkit-autofill:hover,
	${Field}.has-error &:-webkit-autofill:focus,
	${Field}.has-error &:-webkit-autofill:active
	{
		-webkit-box-shadow: 0 0 0 30px #f2dede inset;
	}
`;

const Textarea = styled.textarea`
	width:100%;
	height:11rem;
	resize:none;
	appearance:none;
	outline:0;

	padding:1em;
	border:1px solid #979797;
	box-sizing:border-box;
	border-radius:0;
	background:#F6F6F5;

	font-size:1.5rem;
	font-family:Helvetica, Arial, sans-serif;

	${Field}.has-error &,
	${Field}.has-error &:focus {
		color:#a94442;
		border-color:#a94442;
		background-color:#f2dede;
	}
`;

const Submit = styled.button`
	position:relative;

	background:#AC4629;
	color:#fff;
	border:0;
	width:100%;
	padding:1.2em 2.338em 1.1em;
	box-sizing:border-box;
	height:6rem;

	font-weight:500;
	font-size:1.6rem;
	line-height:normal;
	letter-spacing:.04em;
	text-decoration:none;
	outline:0;

	cursor:pointer;

	@media (min-width:1024px) {
		font-size:1.6rem;
	}
`;

const Success = styled.div`
	padding:4rem 0;
	text-align:center;

	@media (min-width:768px) {
		display:flex;
		align-items:center;
		text-align:left;
	}
`;

const SuccessIcon = styled.div`
	margin:0 auto 2rem;

	width:64px;
	height:64px;
	background:url(${icon}) no-repeat center center;
	background-size:contain;

	@media (min-width:768px) {
		margin:0;
		flex:0 0 auto;
	}
`;

const SuccessContent = styled.div`
	@media (min-width:768px) {
		padding-left:3rem;

		& br {
			display:none;
		}
	}
`;

const SuccessTitle = styled.div`
	margin:0 0 .5em;

	font-size:2.1rem;
	font-weight:500;
`;

const SuccessText = styled.div`
	font-size:1.6rem;
	font-weight:400;
	line-height:1.3em;
`;

const spin = keyframes`
	from { transform:rotate(0deg); }
	to { transform:rotate(360deg); }
`;

export const Loader = styled.div`
	position:absolute;
	left:50%;
	top:50%;

	height:1.75em;
	width:1.75em;

	opacity:0;
	visibility:hidden;
	transform-origin:0 0;
	transform:scale(.5) translateZ(1px) translateX(-50%) translateY(-50%);
	transition:all .3s cubic-bezier(.4,0,.2,1);
	will-change:opacity, visibility;

	&::after,
	&::before {
		position:absolute;
		top:0;
		left:0;

		display:block;
		content:"";
		height:100%;
		width:100%;
		box-sizing:border-box;

		border-radius:100%;
		border-width:0.25em;
		border-style:solid;
	}

	&::before {
		border-color:rgba(0, 0, 0, 0.15);
		border-radius:100%;
	}

	&::after {
		border-color:#ffffff rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
		box-shadow:0 0 0 .1em rgba(0, 0, 0, 0);
		animation:${spin} 0.6s linear infinite;
	}

	${Submit}.is-loading & {
		opacity:1;
		visibility:visible;
		transform:scale(1) translateZ(1px) translateX(-50%) translateY(-50%);
		transition-delay:.15s;
	}
`;

const SubmitLabel = styled.div`
	transition:all .3s cubic-bezier(.4,0,.2,1);
	will-change:opacity, visibility;
	opacity:1;
	visibility:visible;

	${Submit}.is-loading & {
		opacity:0;
		visibility:hidden;
	}
`;

/*
 *
 */
export default () => {

	const [loading, setLoading] = React.useState(false);
	const [success, setSuccess] = React.useState(false);
	const [errors, setErrors] = React.useState({});

	function handleSubmit (e) {
		e.preventDefault();

		if(loading) {
			return;
		}

		setLoading(true);

		const myForm = document.getElementById('contact-form');
		const formData = Object.fromEntries(new FormData(myForm));

		const errors = ['firstname', 'lastname', 'email', 'msg'].reduce((out, field) => {
			return {
				...out,
				[field]: !(formData[field] || '').trim()
			}
		}, {});

		setErrors(errors);

		//console.log('errors', errors);

		if (Object.entries(errors).some(([key, hasErr]) => hasErr)) {
			setLoading(false);
			return;
		}

		superagent
			.post('https://submit-form.com/s0p7o9yA')
			.set('Content-Type', 'application/json')
			.set('Accept', 'application/json')
			.send({
				nom: `${formData.firstname || ''} ${(formData.lastname || '').toUpperCase()}`,
				email: `${formData.email || '-'}`,
				téléphone: `${formData.phone || '-'}`,
				message: `${formData.msg}`
			})
				.then(() => {
					setLoading(false);
					setSuccess(true);
				})
				.catch(error => {
					setLoading(false);
					console.log(error);
				});
	}

	return (
		<Main>
			<Content>
				<p>
					Prise de rendez-vous et renseignements par téléphone de 8h à 21h ou via le formulaire ci-dessous.<br /><br />
					Permanence téléphonique de 8h à 9h sauf le week-end.<br />
					Sur messagerie pendant les consultations.
				</p>
			</Content>

			<Split>
				<Button
					align="left"
					href="tel:0032478260157"
					label="+32 478 26 01 57"
				/>

				<Note>N'hésitez pas à me laissez un message, je vous recontacterai dès que possible.</Note>
			</Split>

			{success ? (
				<Success>
					<SuccessIcon />
					<SuccessContent>
						<SuccessTitle>
							Merci pour votre message
						</SuccessTitle>
						<SuccessText>
							Votre message a bien été envoyé.<br /> Je vous répondrai dés que possible.
						</SuccessText>
					</SuccessContent>
				</Success>
			) : (
				<Form onSubmit={handleSubmit} id="contact-form">
					<input type="checkbox" name="topyenoh" style={{ display: 'none'}} tabIndex="-1" autoComplete="off" />
					<Row>
						<Field className={errors['firstname'] ? 'has-error' : null}>
							<Label htmlFor="firstname">Prénom</Label>
							<Input id="firstname" name="firstname" />
						</Field>
						<Field className={errors['lastname'] ? 'has-error' : null}>
							<Label htmlFor="lastname">Nom</Label>
							<Input id="lastname" name="lastname" />
						</Field>
					</Row>
					<Row>
						<Field className={errors['email'] ? 'has-error' : null}>
							<Label htmlFor="email">E-mail</Label>
							<Input id="email" name="email" />
						</Field>
						<Field className={errors['phone'] ? 'has-error' : null}>
							<Label htmlFor="phone">Téléphone</Label>
							<Input id="phone" name="phone"/>
						</Field>
					</Row>
					<Row>
						<Field className={errors['msg'] ? 'has-error' : null}>
							<Label htmlFor="msg">Message</Label>
							<Textarea id="msg" name="msg" />
						</Field>
					</Row>

					<Submit type="submit" className={loading ? 'is-loading' : null}>
						<Loader />
						<SubmitLabel>Envoyer</SubmitLabel>
					</Submit>
				</Form>
			)}

		</Main>
	);
}


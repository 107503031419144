/*
 *
 */
import * as React from 'react';
import Page from '../components/page';
import Form from '../components/form';

/*
 *
 */
const PageContent = () => {
	return (
		<Page
			title="Demandes de rendez-vous"
			intro="Toute personne peut un jour ou l’autre sentir qu’elle a besoin d’être aidée, de parler, de déposer sa souffrance ou tout simplement d’être entendue."
			content={null}
		>
			<title>Rendez-vous — Isabelle Kopp</title>
			<Form />
		</Page>
	);
};

export default PageContent;
